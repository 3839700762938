import home from './home/index'
import common from './common/index'
import detail from './detail/index'
import publish from './publish/index'
import index from './index/index.js'
// import header from './header/index.js'

export default function(http,baseUrl,uploadUrl) {
    return {
        ...home(http,baseUrl,uploadUrl),
        ...common(http,baseUrl,uploadUrl),
        ...detail(http,baseUrl,uploadUrl),
        ...publish(http,baseUrl,uploadUrl),
        ...index(http,baseUrl,uploadUrl),
        // ...header(http,baseUrl,uploadUrl),
    }
}