import axios from 'axios';
//自动切换环境
// axios.defaults.baseURL = process.env.VUE_APP_BASEURL
//设置超时时间
axios.defaults.timeout = 10000;
// post请求头
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'

export function get(url, params){
  return new Promise((resolve, reject) =>{
      axios.get(url, {params}).then(res =>{
          resolve(res.data);
      }).catch(err =>{
          reject(err.data);
      })
  });
}

// 封装post方法
export function post(url, params){
  return new Promise((resolve, reject) =>{
      axios.post(url, params).then(res =>{
          resolve(res.data);
      }).catch(err =>{
          reject(err.data);
      })
  });
}

// 封装put方法
export function put(url, params){
  console.log("axios.put",axios.put)
  return new Promise((resolve, reject) =>{
      axios.put(url, params).then(res =>{
          resolve(res.data);
      }).catch(err =>{
          reject(err.data);
      })
  });
}