import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/index/index.vue')
  },
  {
    path: '/index/:id',
    name: 'index',
    component: () => import('../views/index/index.vue')
  },
  {
    path: '/detail',
    name: 'Detail',
    component: () => import('../views/detail/index.vue')
  },
  {
    path: "/result",
    name: "Result",
    component: () => import('../views/publish/index.vue')
  },
  {
    path: "/business",
    name: "Business",
    component: () => import('../views/business/index.vue')
  },
  {
    path: "/permit",
    name: "permit",
    component: () => import('../views/permit/index.vue')
  },
  {
    path: "/mechanism",
    name: "mechanism",
    component: () => import('../views/mechanism/index.vue')
  },
  {
    path: "/standard",
    name: "standard",
    component: () => import('../views/standard/index.vue')
  },
  {
    path: "/tradingRules",
    name: "tradingRules",
    component: () => import('../views/tradingRules/index.vue')
  },
  {
    path: "/increaseValue",
    name: "increaseValue",
    component: () => import('../views/increaseValue/index.vue')
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, form, next) => {
  window.scrollTo(0,0);
  next()
})

export default router
