export default function (http,baseUrl,uploadUrl) {
  return {
    getLanguage(params) {
	  console.log(uploadUrl);
      return http.post(`${baseUrl}/MetaImpApi/GetMetaTree`, params);
    },
    getCategory() {
      return http.get(`${baseUrl}/book/category/list`);
    }
  }
}
