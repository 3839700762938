<template>
  <div class="header">
    <div class="top">
      <div class="flex-between">
        <div class="top-font left">
          <i class="el-icon-s-home" style="color: #939393;"></i>
          <input type="button" value="墨库首页" />
            <i class="el-icon-position" style="color: #939393;"></i>
            <input type="button" value="广州"/>
        </div>
        <div  style="display:flex;align-items:center;">
          <el-button @click="toPublish" style="background-color:#13BBB9;color:#FFFFFF;height: 30px;line-height: 10px;">发布</el-button>
          <div class="top-button right">
          <!-- https://sso.mekoom.com/web/login.html?ReturnUrl -->
          <button @click="toLogin">登录/注册</button> 
          <!-- <span class="button-border"></span>
          <button @click="toLogin">我的订单</button> -->
          <span class="button-border"></span>
          <button v-if="false" @click="toLogin">会员中心</button>
          <!-- <span class="button-border"></span>
          <button>客户服务</button>
          <span class="button-border"></span>
          <button>手机墨库</button> -->
        </div>
        </div>
      </div>
    </div>
    <div class="middle">
      <div class="main flex-start">
        <div class="logo center"  @click="toIndex">
          <div class="mekoom-back"><img src="@/assets/logo.png"></div>
          <span>墨库</span>
        </div>
        <div class="search flex-column-center">
          <div class="search-box center">
            <input v-model="searchText" type="text" placeholder="请输入书名/作者">
            <div class="btn center" @click="toList">
              <button style="background-color: #13BBB9; border: 0px;">
                <img src="@/assets/search.png">
              </button>
            </div>
          </div>
          <div class="hotword">
            <button class="hotword-list" @click="toSearch" value="色彩静物从入门到精通">色彩静物从入门到精通</button>  |  
            <button class="hotword-list" @click="toSearch" value="致加西亚的信">致加西亚的信</button>  |  
            <button class="hotword-list" @click="toSearch" value="百衲书画价值考">百衲书画价值考</button>  ｜  
            <button class="hotword-list" @click="toSearch" value="彩虹之门">彩虹之门</button>  ｜  
            <button class="hotword-list" @click="toSearch" value="魏碑名品">魏碑名品</button>
          </div>
        </div>
        <div class="button flex-end">
          <!-- <button class="button-mekoom" @click="toMeKoom">
            <img src="@/assets/mekoom.png">
          </button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'commonheader',
    data() {
      return {
        hotwordList: [],
        searchText: "",
      }
    },
    components: {},
    mounted() {},
    watch: {
      // "$route": {
      //   handler(val) {
      //     console.log("route.val:"+val.url)
      //   }
      // },
      searchText(val) {
        if(!val) {
           this.$store.dispatch("setSearchText","")
        }
      }
    },
    methods: {
      toLogin() {
        window.location.href = `https://console.mekoom.com?ReturnUrl=${encodeURIComponent(window.location.href)}`;
      },
      toIndex() {
        if(this.$route.fullPath == '/') {
          window.location.reload()
        }else {
          this.$router.push('/');
        }
      },
      // 跳转发布
      toPublish() {
        this.$router.push('/result');
      },
      toList() {
        // 当前页搜索，无需跳转
      //   this.$router.push({
      //     name: "Result",
      //     query: {
      //       searchText: this.searchText,
      //       name: this.searchText
      //     }
      //   })
        this.$store.dispatch('setSearchText',this.searchText)
      },
      
      toMeKoom() {
        window.location.href = "https://www.mekoom.com/"
      },
      keyInText(e) {
        this.searchText = e;
      },
      
      toSearch(event) {
        this.searchText = event.target.value
        this.toList();
      }
    }
  }
</script>

<style scoped lang="scss">
  .header {
    width: 100%;
    height: 150px;
    
    .top {
      height: 35px;
      width: 100%;
      background-color: #083546;
      
      .flex-between {
        height: 100%;
display: flex;
      align-items: center;
      justify-content: space-between;
      }
      .top-font {
        width: 200px;
        margin-left: 50px;
        margin-top: 7px;
        
        i {
          width: 11px;
          height: 11px;
          margin-right: 6px;
        }
        input {
          width: 48px;
          height: 17px;
          margin-right: 20px;
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 400;
          line-height: 17px;
          color: #939393;
          opacity: 1;
          background-color: #083546;
          border: 0px;
          text-align: left;
        }
      }
      
      .top-button {
        margin-right: 100px;
        text-align: center;
        margin-top: 5px;
        
        button {
          width: 70px;
          height: 17px;
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 400;
          line-height: 17px;
          color: #939393;
          background-color: #083546;
          opacity: 1;
          border: 0px;
        }
       
        .button-border {
          width: 0px;
          height: 12px;
          border: 1px solid #939393;
          opacity: 1;
        }
      }     
    }

    .middle {
      height: 115px;
      width: 100%;
      background-color: #FFFFFF;

      .main {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 110px;
        margin: 0 60px;

        .logo {
          font-size: 15px;
          font-weight: 400;
          color: #ffffff;
          cursor: pointer;
          .mekoom-back {
            display: flex;
            width: 95px;
            height: 95px;
            // background-color: #FAFAFA;
            justify-content: center;
            align-items: center;
            
            img {
              width: 60%;
              height: 60%;
              box-shadow: 0px 0px 3px 2px #F7F7F7 ;
              border-radius: 5px;
            }
          }
           
          span {
            // width: 59px;
            height: 38px;
            font-size: 27px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            line-height: 38px;
            color: #413434;
            // letter-spacing: 200px;
            opacity: 1;
          }
        }

        .search {
          width: 540px;
          margin-top: 30px;
          text-align: center;
          align-content: center;

          .search-box {
            width: 100%;
            height: 36px;
            font-size: 14px;
            color: #939393;

            input {
              width: 472px;
              height: 36px;
              background: #FFFFFF;
              border-left: 1px solid #eaeaea;
              border-top: 1px solid #eaeaea;
              border-bottom: 1px solid #eaeaea;
              border-right: none;
              border-radius: 18px 0 0 18px;
              outline: none;
              padding: 0 20px;
              color: #939393;
            }

            .btn {
              width: calc(100% - 472px);
              height: 36px;
              background: #13BBB9;
              border-radius: 0 18px 18px 0;

              img {
                width: 25px;
                height: 25px;
              }
            }
          }

          .hotword {
            width: 100%;
            font-size: 12px;
            color: #cccccc;
            margin-top: 8px;

            .item {
              cursor: pointer;
              max-width: 20%;
              height: 14px;
              padding: 0 10px;
              border-left: 1px solid #cccccc;

              &:nth-child(1) {
                border-left: none;
                color: #FE0036;
              }
            }
            
            .hotword-list {
              background-color: #FFFFFF;
            }
          }
        } 
      }
      
      .button {
        margin-top: 10px;
        align-content: left;
        margin-right: 200px;
        
        .button-shoppingcar {
          width: 120px;
          height: 36px;
          border: 1px solid #EAEAEA;
          opacity: 1;
          border-radius: 50px;
          text-align: center;
          
          i {
            width: 17px;
            height: 16px;
            opacity: 1;
          }
          
          span {
            display: inline-block;
            width: 18px;
            height: 18px;
            background: #FE0036;
            border-radius: 50%;
            opacity: 1;
          }
        }
        
        .button-mekoom {
          width: 100px;
          height: 30px;
          border: 1px solid #EAEAEA;
          margin-left: 15px;
          opacity: 1;
          border-radius: 50px;
          background-color: #FFFFFF;
        }
      }
    }
    
    button {
      border: 0px;
      cursor: pointer;
    }
  }
</style>
