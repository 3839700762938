import Vue from 'vue'
import ElementUI from 'element-ui'
import App from './App.vue'
import router from './router'
import store from './store'
import {get,post,put} from './utils/http'
import apis from './api/index'
import "./common/style/base.css"
import 'element-ui/lib/theme-chalk/index.css';
import {baseUrl,uploadUrl} from './api/config.js'
Vue.use(ElementUI)

Vue.config.productionTip = false
Vue.prototype.$Axios = apis({get,post,put},baseUrl,uploadUrl)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

