export default function(http,baseUrl,uploadUrl) {
    return {
      // 获取供应信息
      getSupplyList(params) {
		console.log(uploadUrl);
        return http.get(`${baseUrl}/book/supply/list`, params);
      },

      // 获取求购信息
      getBookList(params) {
        return http.get(`${baseUrl}/book/sale/list`, params);
      },
    }
}