<template>
  <div class="home">
    <div class="head flex-between">
      <div class="left">
        <div class="navbar-title">书籍分类</div>
        <div class="infinite-list-wrapper" style="overflow-y: auto;">
          <ul class="'navbar-list'" infinite-scroll-disabled="disabled">
            <li :class="currentId ? 'navbar-item' : 'navbar-item-active'" @click="showAll()">全部</li>
            <li :class="
                currentId == item.id ? 'navbar-item-active' : 'navbar-item'
              " v-for="(item, index) in navbarList" :key="index" @click="toggleContent(item)">
              {{ item.name }}
            </li>
          </ul>
        </div>
      </div>
      <div class="right">
        <router-view />
      </div>
    </div>
    <div class="bottom">
      <div>Copyright © 2018-2023 www.mekoom.com All Rights Reserved版权所有 中华商务贸易公司</div>
      <div>
        <a class="tag-a" href="https://beian.miit.gov.cn/">粤ICP备11004666号</a>
        <a class="tag-a" @click="toRouter('/business')"> 营业执照</a>
        <a class="tag-a" @click="toRouter('/permit')"> 出版物经营许可证：新出发粤进批字第 003 号</a>
		<a class="tag-a" @click="toRouter('/increaseValue')"> 增值电信业务经营许可证：粤B2-20181324</a>
      </div>
      <div>
        <a class="tag-a" @click="toLinkMall('https://cdn.mekoomall.com/static/pc/manager/墨库平台对用户交易纠纷处理的机制或方案.txt')">用户交易纠纷处理机制方案</a>
        <a class="tag-a" @click="toLinkMall('https://cdn.mekoomall.com/static/pc/manager/墨库平台对入驻经营者的要求和规范.txt')"> 入驻经营者的要求和规范</a>
        <a class="tag-a" @click="toLinkMall('https://cdn.mekoomall.com/static/pc/manager/墨库服务协议及交易规则.txt')"> 服务协议及交易规则</a>
        <a class="tag-a" @click="toLinkMall('https://cdn.mekoomall.com/static/pc/manager/墨库隐私政策.txt')"> 隐私政策</a>
      </div>
      <div>本站所收录的作品、社区话题、用户评论、用户上传内容或图片等均属用户个人行为。如前述内容侵害您的权益，欢迎举报投诉，一经核实，立即删除，本站不承担任何责任</div>
      <div>地址：广州市荔湾区百花路花地好邻舍商业中心12层</div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Home",
    components: {},
    created() {
      this.getCategoryList();
    },
    data() {
      return {
        navbarList: [],
        loading: false,
        count: 100,
        currentId: ""
      };
    },
    watch: {
      "$route": {
        handler(newVal) {
          this.currentId = newVal.params.id
        }
      }
    },
    methods: {
      // 选择书籍类型
      toggleContent(value) {
        // console.log(value)
        this.$router.replace({
          path: `/index/${value.id}`
        })
      },
      toRouter(path) {
        this.$router.replace({
          path: path
        })
      },
      toLinkMall(path) {
        window.open(path);
      },
      // 全部
      showAll() {
        this.$router.push({
          name: 'Home'
        })
      },
      handleClick() {},
      // 获取书籍类型
      async getCategoryList() {
        const data = await this.$Axios.getCategory();
        console.log(data.data)
        this.navbarList = data.data.filter(v => {
          return v.id != 5 && v.id != 3
        });
        this.count = data.data.length;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .home {

    // width: 1200px;
    // margin: 0 auto;
    .head {
      width: 100%;

      .left {
        width: 250px;
        height: calc(100vh - 40px);
        background: #f2f2f2;
        padding-left: 56px;
        padding-top: 16px;

        .navbar-title {
          margin-bottom: 20px;
          font-size: 20px;
          color: #413434;
          font-weight: 500;
        }

        .navbar-list {
          list-style: none;
        }

        .navbar-item {
          font-size: 15px;
          color: #413434;
          margin-bottom: 16px;
          padding-left: 16px;
          position: relative;
          cursor: pointer;
        }

        .navbar-item::before {
          position: absolute;
          left: 0;
          top: 50%;
          content: "";
          height: 5px;
          width: 5px;
          border-radius: 50%;
          background-color: #413434;
          cursor: pointer;
        }

        .navbar-item:hover {
          color: #13bbb9;
        }

        .navbar-item-active {
          margin-bottom: 16px;
          padding-left: 16px;
          font-size: 15px;
          font-weight: 500;
          color: #13bbb9;
          position: relative;
        }

        .navbar-item-active::before {
          position: absolute;
          left: 0;
          top: 50%;
          content: "";
          height: 5px;
          width: 5px;
          border-radius: 50%;
          background-color: #13bbb9;
        }
      }

      .right {
        width: calc(100% - 240px);
        padding-left: 27px;
        padding-top: 16px;
        background-color: #fafafa;

        .content-title {
          margin-bottom: 20px;
          margin-left: 23px;
          font-size: 20px;
          color: #413434;
          font-weight: 500;
        }
      }
    }
    
    .bottom {
      width: 100%;
      height: 113px;
      margin-top: 65px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      line-height: 12px;
      color: #413434;
      opacity: 1;
      text-align: center;
      a{
        cursor: pointer;
      }
      a:hover {
        color: #13bbb9;
      }
      div {
        margin-top: 10px;
      }
      
      .tag-a {
        text-decoration: none;
        width: 100%;
        height: 21px;
        font-size: 15px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 18px;
        color: #413434;
        opacity: 1;
      }
    }
  }
</style>
