import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    searchText: ""
  },
  mutations: {
    SET_SEARCHTEXT(state,searchText) {
      state.searchText = searchText
    }
  },
  actions: {
    setSearchText({commit},searchText) {
      commit('SET_SEARCHTEXT',searchText)
    }
  },
  modules: {
  }
})
