<template>
  <div id="app" :style="{minHeight: minHeight}">
    <commonHeader />
    <Home/>
  </div>
</template>

<script>
import commonHeader from "./components/Header.vue"
import Home from "./views/Home/Home.vue"
export default {
  name: "moku",
  components: {
    commonHeader,
    Home
  },
  data() {
    return {
      minHeight: window.innerHeight + 'px'
    }
  },
  mounted() {
  }
}
</script>

<style lang="scss">
#app {
  background: #f6f6f6;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
